import React from 'react';
import { Helmet } from "react-helmet"
import Image from '../components/image/Image';
import './styles/styles.scss';

const NotFoundPage = () => {

    return (
        <>
            <Helmet title="404 | Digital Building Data">
                <meta name="description" content="Digital Building Data" />
                <link href="https://api.fontshare.com/v2/css?f[]=satoshi@700,500,400&display=swap" rel="stylesheet" />
            </Helmet>
            <div className='header__container header__container--notFound position-relative'>
                <div className='container navigation_container navigation--notFound'>
                    <div className='header header--notFound'>
                        <div className='header__left header__left--notFound xl-big'>
                            <a href='/'>
                                <Image src='logo.svg'
                                    className='mx-auto shadow-xl navigation-logo navigation--notFound'
                                    alt='logo' />
                            </a>
                            <p className='white'>Page</p>
                            <p className='blue'>not found</p>
                            <p className='link--notFound white xl-normal'>
                                <a href="/">Go back to the homepage</a>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFoundPage;